import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { doLogin, displayResponsiveModal } from '../actions';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: 'password', i_agree: this.props.isLogged || false, already_agree: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, i_agree: !this.state.i_agree });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { password } = this.state;
    const { doLogin, displayResponsiveModal } = this.props;
    this.setState({ already_agree: true });
    displayResponsiveModal("home");
    if (!this.props.isLogged) {
      doLogin(password); 
    }
  }

  render () {
    const { password } = this.state;
    const { errorMessage } = this.props;

    const publicSrc = `${process.env.PUBLIC_URL}/logos`;
    
    
    
    
    const logo_fondationfr = `${publicSrc}/logo-fondation-de-france.png`;
    const logo_ageo = `${publicSrc}/logo-ageo.png`;
    const logo_littorisque = `${publicSrc}/logo-littorisque.jpg`;
    const logo_interreg = `${publicSrc}/logo-interreg.png`;
    const logo_logoCNRS = `${publicSrc}/logo-cnrs.png`;
    const logo_osirisc = `${publicSrc}/logo-osi.svg`;
    const logo_ubo = `${publicSrc}/logo-ubo.png`;
    const logo_iuem = `${publicSrc}/logo-iuem.png`;
    const logo_prefecture = `${publicSrc}/logo-prefecture-bretagne.png`;
    const logo_cd29 = `${publicSrc}/Logo2022_cd_29.PNG`;
    const logo_DEAL = `${publicSrc}/logo-prefet_reunion.svg`;
    const logo_BRGM = `${publicSrc}/logo-BRGM.png`;
    const logo_univREU = `${publicSrc}/logo-universite_reunion.png`;
    const logo_nout_bord_mer = `${publicSrc}/logo-nout_bord_mer.jpg`;
    const logo_region_reunion = `${publicSrc}/logo-region_reunion.webp`;
    const logo_espace_dev = `${publicSrc}/logo-espace_dev.png`;
    const logo_entropie = `${publicSrc}/logo-entropie.jpg`;
        

    return (
      <Modal
        id="loginModal"
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <div className="modal-logo"></div>
        <div className="modal-separator"></div>
        <div className="text-intro">
          <span className="browser-support">
            Attention, cette application est en cours de développement.
            <a target="_blank" rel="noopener noreferrer">
            </a>
          </span>

          <p>
          OSI est une application de cartographie en ligne dite « WEB-SIG » permettant de représenter de manière spatio-temporelle :
          </p>
          <ul type="1">
            <li>les indicateurs décrivant les quatre composantes de la vulnérabilité systémique à l’érosion et la submersion marines (Aléas, Enjeux, Gestion, Représentations),</li>
            <li>les indices décrivant la vulnérabilité des territoires, calculés en combinant les indicateurs</li>
          </ul>
          <p>Adressée autant aux chercheurs qu’aux gestionnaires, cette application doit servir d’interface d’analyse multicritère et d’aide à la gestion de la vulnérabilité aux risques côtiers. Sa conception repose sur deux principes directeurs :</p>
          <ul>
            <li>
              L’intégrité scientifique, comprenant le partage d’une méthodologie élaborée par une équipe pluridisciplinaire permettant de mettre en œuvre une analyse systémique de la vulnérabilité littorale.
            </li>
            <li>
              La pertinence de l’outil s’appuie sur l’intégration des équipes techniques des collectivités territoriales partenaires, depuis la conception des indicateurs jusqu’à la visualisation de la donnée.
            </li>
          </ul>
          <p>
            Les données brutes des indicateurs sont collectées selon des protocoles spécifiques à chaque indicateur. Ces données sont mises en forme suivant une cotation à cinq niveaux, selon un protocole défini pour chaque indicateur. Ces éléments sont détaillés dans les fiches méthodologiques.
          </p>
          <p>
            Les données sont ensuite rapportées à un carroyage de 50 à 800 m de côté (à partir du carroyage de l’INSEE de 200m) et en projection conforme suivant la recommandation de la Directive INSPIRE. 
          </p>
          <p>
            Enfin les indices résultent de la combinaison de plusieurs indicateurs. Ils visent à fournir une information synthétique sur un thème, une composante, ou une problématique spécifique.
          </p>
          <p>
            Les résultats présentés dans cette application n’engagent que l’équipe scientifique impliquée. Certains d’entre eux ont cependant été obtenus avec l’aide des territoires pilotes. Qu’ils soient ici remerciés.
          </p>
          <p>
            Les éléments s’inscrivent dans le transfert de l'application OSI à l'observatoire Nout bord'mer par l’Observatoire des risques côtiers en Bretagne OSIRISC porté par l’Institut Universitaire Européen de la Mer.
          </p>
          <p>
          Vous souhaitez contacter l'observatoire Nout Bord'mer?<br />
          Adressez nous votre message à <a href="observatoire-littoral-reunion@developpement-durable.gouv.fr">observatoire-littoral-reunion@developpement-durable.gouv.fr</a>
          </p>
        </div>
        <div className="modal-separator"></div>
        <div id="imgLogin" style={{marginTop: "1em"}}>
            <p className="credits-terms"> Partenaires du projet </p>
            <a target="_blank" rel="noopener noreferrer" href="https://www.univ-brest.fr/">
                <img height="50px" src={logo_ubo} alt="logo UBO"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www-iuem.univ-brest.fr/">
                <img height="70px" src={logo_iuem} alt="logo IUEM" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.cnrs.fr/">
                <img height="70px" src={logo_logoCNRS} alt="logo CNRS" />
            </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.risques-cotiers.fr/connaitre-les-risques-cotiers/projets/osirisc/">
            <img height="70px" src={logo_osirisc} alt="logo Osirisc" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.risques-cotiers.fr/connaitre-les-risques-cotiers/projets/littorisques/">
            <img height="70px" src={logo_littorisque} alt="logo littorisque" />
          </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.univ-reunion.fr/">
            <img height="40px" src={logo_univREU} alt="logo Université de La Réunion" />
          </a>
            <a target="_blank" rel="noopener noreferrer" href="https://brgm-fds.wixsite.com/website">
            <img height="70px" src={logo_BRGM} alt="logo BRGM" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://regionreunion.com/">
            <img height="70px" src={logo_region_reunion} alt="logo Région Réunion" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.espace-dev.fr">
            <img height="70px" src={logo_espace_dev} alt="logo Espace Dev" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://umr-entropie.ird.nc/index.php/home">
            <img height="70px" src={logo_entropie} alt="logo Entropie" />
          </a>
        </div>
        <div style={{marginTop: "1em"}}>
          <p className="credits-terms"> Réalisation co-financée par </p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.fondationdefrance.org/">
            <img height="70px" src={logo_fondationfr} alt="logo Fondation de France"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="http://www.bretagne.developpement-durable.gouv.fr/">
            <img height="70px" src={logo_prefecture} alt="logo prefecture de Bretagne" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://ageoatlantic.eu/">
            <img height="70px" src={logo_interreg} alt="logo interreg"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://ageoatlantic.eu/">
            <img height="80px" src={logo_ageo} alt="logo ageo"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.finistere.fr/">
            <img height="90px" src={logo_cd29} alt="logo conseil departemental du finistere" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.reunion.gouv.fr/">
            <img height="90px" src={logo_DEAL} alt="logo Préfet Réunion" />
          </a>
        </div>
        <div className="modal-separator"></div>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="grid-center">
            <div className="col-md-auto">
              <div className="checkbox">
                {errorMessage && (
                  <div className="error">
                    <span>{errorMessage}</span>
                  </div>
                )}
                <input type="hidden" placeholder="Mot de passe" name="password" value={password} onChange={e => this.handleChange(e)} />
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.i_agree}
                    onChange={this.handleChange}
                  />  J&apos;ai pris connaissance de ces informations
                </label>
              </div>
              {
                (this.state.i_agree || this.props.isLogged) && 
                <button variant="primary" type="submit">Valider</button>
              }
            </div>
          </div>

        </form>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    errorMessage: state.doLogin.errorMessage,
    isLogged: state.doLogin.isLogged
  }),
  { doLogin, displayResponsiveModal },
)(Home);
